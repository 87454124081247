/* global gtag */
// import React from 'react'
// /* Logs when the client route changes */
// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = () => {
  if (!process.env.GATSBY_DEV_MODE && gtag) {
    gtag(
      'event',
      'page_view' /* , {
        page_title: '<Page Title>',
        page_location: '<Page Location>',
        page_path: '<Page Path>'
        send_to: '<GA_MEASUREMENT_ID>'
    } */,
    );
  }
};
