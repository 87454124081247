exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-ru-jsx": () => import("./../../../src/pages/index.ru.jsx" /* webpackChunkName: "component---src-pages-index-ru-jsx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-dog-walking-on-a-sunny-day-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-dog-walking-on-a-sunny-day/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-dog-walking-on-a-sunny-day-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-dog-walking-on-a-sunny-day-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-dog-walking-on-a-sunny-day/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-dog-walking-on-a-sunny-day-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-stranger-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-stranger/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-stranger-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-stranger-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-stranger/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-stranger-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-walk-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-walk/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-walk-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-walk-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/a-walk/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-a-walk-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-one-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstract-composition-one/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-one-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-one-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstract-composition-one/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-one-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-two-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstract-composition-two/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-two-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-two-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstract-composition-two/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstract-composition-two-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstraction-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstraction/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstraction-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstraction-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/abstraction/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-abstraction-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-albedo-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/albedo/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-albedo-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-albedo-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/albedo/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-albedo-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-art-of-music-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/art-of-music/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-art-of-music-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-art-of-music-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/art-of-music/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-art-of-music-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-1-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-and-red-1/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-1-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-1-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-and-red-1/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-1-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-2-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-and-red-2/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-2-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-2-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-and-red-2/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-and-red-2-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-fantasy-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-fantasy/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-fantasy-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-fantasy-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/blue-fantasy/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-blue-fantasy-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-chasing-light-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/chasing-light/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-chasing-light-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-chasing-light-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/chasing-light/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-chasing-light-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-citrinitas-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/citrinitas/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-citrinitas-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-citrinitas-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/citrinitas/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-citrinitas-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-city-night-lights-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/city-night-lights/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-city-night-lights-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-city-night-lights-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/city-night-lights/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-city-night-lights-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-cold-spell-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/cold-spell/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-cold-spell-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-cold-spell-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/cold-spell/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-cold-spell-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-dreams-city-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/colorful-dreams-city/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-dreams-city-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-dreams-city-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/colorful-dreams-city/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-dreams-city-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-life-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/colorful-life/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-life-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-life-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/colorful-life/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-colorful-life-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dancers-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/dancers/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dancers-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dancers-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/dancers/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dancers-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-deja-vu-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/deja-vu/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-deja-vu-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-deja-vu-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/deja-vu/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-deja-vu-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dream-landscape-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/dream-landscape/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dream-landscape-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dream-landscape-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/dream-landscape/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-dream-landscape-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-every-obstacle-is-an-opportunity-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/every-obstacle-is-an-opportunity/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-every-obstacle-is-an-opportunity-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-every-obstacle-is-an-opportunity-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/every-obstacle-is-an-opportunity/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-every-obstacle-is-an-opportunity-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fall-meets-winter-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fall-meets-winter/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fall-meets-winter-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fall-meets-winter-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fall-meets-winter/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fall-meets-winter-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-feeling-of-autumn-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/feeling-of-autumn/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-feeling-of-autumn-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-feeling-of-autumn-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/feeling-of-autumn/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-feeling-of-autumn-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-diptych-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-diptych/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-diptych-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-diptych-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-diptych/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-diptych-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-one-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-one/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-one-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-one-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-one/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-one-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-two-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-two/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-two-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-two-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fila-vitae-two/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fila-vitae-two-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fire-in-the-head-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fire-in-the-head/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fire-in-the-head-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fire-in-the-head-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/fire-in-the-head/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-fire-in-the-head-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-golden-grape-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/golden-grape/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-golden-grape-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-golden-grape-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/golden-grape/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-golden-grape-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-green-spirit-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/green-spirit/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-green-spirit-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-green-spirit-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/green-spirit/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-green-spirit-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-dream-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-a-dream/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-dream-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-dream-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-a-dream/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-dream-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-secret-place-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-a-secret-place/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-secret-place-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-secret-place-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-a-secret-place/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-a-secret-place-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-front-of-the-light-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-front-of-the-light/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-front-of-the-light-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-front-of-the-light-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-front-of-the-light/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-front-of-the-light-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-gold-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-gold/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-gold-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-gold-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-gold/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-gold-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-isolation-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-isolation/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-isolation-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-isolation-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-isolation/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-isolation-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-scarlet-sails-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/In-the-arms-of-scarlet-sails/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-scarlet-sails-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-scarlet-sails-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/In-the-arms-of-scarlet-sails/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-scarlet-sails-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-the-forest-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/In-the-arms-of-the-forest/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-the-forest-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-the-forest-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/In-the-arms-of-the-forest/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-arms-of-the-forest-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-forest-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-the-forest/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-forest-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-forest-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-the-forest/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-forest-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-night-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-the-night/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-night-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-night-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/in-the-night/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-in-the-night-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-just-gotta-keep-going-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/just-gotta-keep-going/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-just-gotta-keep-going-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-just-gotta-keep-going-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/just-gotta-keep-going/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-just-gotta-keep-going-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-land-of-dreams-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/land-of-dreams/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-land-of-dreams-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-land-of-dreams-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/land-of-dreams/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-land-of-dreams-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-light-despite-all-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/light-despite-all/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-light-despite-all-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-light-despite-all-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/light-despite-all/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-light-despite-all-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-1-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-1/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-1-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-1-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-1/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-1-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-2-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-2/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-2-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-2-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-2/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-2-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-3-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-3/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-3-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-3-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-3/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-3-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-4-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-4/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-4-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-4-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-4/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-4-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-in-tenebris-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-in-tenebris/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-in-tenebris-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-in-tenebris-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/lux-in-tenebris/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-lux-in-tenebris-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-memories-of-summer-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/memories-of-summer/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-memories-of-summer-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-memories-of-summer-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/memories-of-summer/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-memories-of-summer-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-music-sound-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/music-sound/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-music-sound-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-music-sound-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/music-sound/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-music-sound-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-never-alone-index-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/never-alone/index.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-never-alone-index-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-new-reality-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/new-reality/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-new-reality-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-new-reality-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/new-reality/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-new-reality-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-one-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-one/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-one-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-one-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-one/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-one-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-three-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-three/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-three-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-three-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-three/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-three-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-two-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-two/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-two-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-two-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/night-walk-two/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-night-walk-two-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nigredo-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/nigredo/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nigredo-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nigredo-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/nigredo/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nigredo-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nonexistent-landscape-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/nonexistent-landscape/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nonexistent-landscape-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nonexistent-landscape-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/nonexistent-landscape/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-nonexistent-landscape-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-diptych-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-diptych/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-diptych-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-diptych-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-diptych/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-diptych-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-one-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-one/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-one-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-one-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-one/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-one-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-two-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-two/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-two-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-two-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/omen-bonum-two/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-omen-bonum-two-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-on-the-wings-of-a-butterfly-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/on-the-wings-of-a-butterfly/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-on-the-wings-of-a-butterfly-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-on-the-wings-of-a-butterfly-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/on-the-wings-of-a-butterfly/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-on-the-wings-of-a-butterfly-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-four-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-four/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-four-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-four-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-four/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-four-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-one-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-one/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-one-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-one-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-one/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-one-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-three-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-three/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-three-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-three-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-three/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-three-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-two-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-two/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-two-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-two-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/other-worlds-two/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-other-worlds-two-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-perpetual-motion-index-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/perpetual-motion/index.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-perpetual-motion-index-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-pink-or-blue-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/pink-or-blue/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-pink-or-blue-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-pink-or-blue-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/pink-or-blue/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-pink-or-blue-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-red-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/red/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-red-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-red-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/red/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-red-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-remember-i-am-a-girl-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/remember-i-am-a-girl/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-remember-i-am-a-girl-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-remember-i-am-a-girl-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/remember-i-am-a-girl/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-remember-i-am-a-girl-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rogue-waves-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/rogue-waves/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rogue-waves-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rogue-waves-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/rogue-waves/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rogue-waves-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rubedo-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/rubedo/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rubedo-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rubedo-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/rubedo/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-rubedo-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sands-of-time-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sands-of-time/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sands-of-time-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sands-of-time-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sands-of-time/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sands-of-time-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sea-breeze-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sea-breeze/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sea-breeze-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sea-breeze-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sea-breeze/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sea-breeze-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-silence-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/silence/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-silence-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-silence-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/silence/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-silence-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-smile-like-you-mean-it-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/smile-like-you-mean-It/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-smile-like-you-mean-it-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-smile-like-you-mean-it-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/smile-like-you-mean-It/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-smile-like-you-mean-it-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-snow-feelings-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/snow-feelings/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-snow-feelings-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-snow-feelings-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/snow-feelings/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-snow-feelings-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sounds-of-nature-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sounds-of-nature/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sounds-of-nature-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sounds-of-nature-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/sounds-of-nature/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-sounds-of-nature-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-i-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/suite-for-strings-I/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-i-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-i-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/suite-for-strings-I/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-i-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-ii-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/suite-for-strings-II/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-ii-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-ii-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/suite-for-strings-II/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-suite-for-strings-ii-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-summer-dreams-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/summer-dreams/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-summer-dreams-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-summer-dreams-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/summer-dreams/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-summer-dreams-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-awakening-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-awakening/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-awakening-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-awakening-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-awakening/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-awakening-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-birth-of-thought-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-birth-of-thought/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-birth-of-thought-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-birth-of-thought-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-birth-of-thought/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-birth-of-thought-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-blue-light-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-blue-light/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-blue-light-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-blue-light-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-blue-light/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-blue-light-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-breath-of-autumn-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-breath-of-autumn/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-breath-of-autumn-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-breath-of-autumn-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-breath-of-autumn/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-breath-of-autumn-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-chill-of-the-evening-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-chill-of-the-evening/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-chill-of-the-evening-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-distance-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-distance/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-distance-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-distance-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-distance/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-distance-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-magic-of-fire-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-magic-of-fire/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-magic-of-fire-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-magic-of-fire-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-magic-of-fire/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-magic-of-fire-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-other-side-of-the-light-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-other-side-of-the-light/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-other-side-of-the-light-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-other-side-of-the-light-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-other-side-of-the-light/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-other-side-of-the-light-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-place-i-want-to-be-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-place-I-want-to-be/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-place-i-want-to-be-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-place-i-want-to-be-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-place-I-want-to-be/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-place-i-want-to-be-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-rhythm-of-the-rain-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-rhythm-of-the-rain/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-rhythm-of-the-rain-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-rhythm-of-the-rain-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-rhythm-of-the-rain/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-rhythm-of-the-rain-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-secret-place-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-secret-place/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-secret-place-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-secret-place-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-secret-place/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-secret-place-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-stories-that-walls-tell-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-stories-that-walls-tell/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-stories-that-walls-tell-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-stories-that-walls-tell-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-stories-that-walls-tell/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-stories-that-walls-tell-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-unknown-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-unknown/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-unknown-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-unknown-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/the-unknown/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-the-unknown-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-transformation-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/transformation/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-transformation-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-transformation-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/transformation/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-transformation-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-twilight-has-its-charm-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/twilight-has-its-charm/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-twilight-has-its-charm-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-twilight-has-its-charm-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/twilight-has-its-charm/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-twilight-has-its-charm-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-unforgettable-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/unforgettable/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-unforgettable-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-unforgettable-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/unforgettable/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-unforgettable-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-1-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-1/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-1-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-1-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-1/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-1-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-10-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-10/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-10-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-10-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-10/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-10-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-11-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-11/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-11-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-11-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-11/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-11-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-12-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-12/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-12-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-12-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-12/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-12-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-13-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-13/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-13-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-13-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-13/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-13-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-14-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-14/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-14-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-14-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-14/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-14-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-2-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-2/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-2-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-2-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-2/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-2-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-3-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-3/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-3-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-3-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-3/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-3-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-4-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-4/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-4-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-4-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-4/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-4-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-5-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-5/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-5-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-5-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-5/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-5-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-6-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-6/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-6-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-6-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-6/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-6-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-7-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-7/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-7-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-7-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-7/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-7-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-8-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-8/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-8-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-8-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-8/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-8-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-9-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-9/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-9-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-9-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/universe-9/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-universe-9-index-ru-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-vivid-memories-index-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/vivid-memories/index.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-vivid-memories-index-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-white-bird-index-en-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/white-bird/index.en.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-white-bird-index-en-mdx" */),
  "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-white-bird-index-ru-mdx": () => import("./../../../src/templates/artwork.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/artworks/white-bird/index.ru.mdx" /* webpackChunkName: "component---src-templates-artwork-jsx-content-file-path-c-uds-projects-yulame-site-src-artworks-white-bird-index-ru-mdx" */),
  "component---src-templates-catalog-jsx": () => import("./../../../src/templates/catalog.jsx" /* webpackChunkName: "component---src-templates-catalog-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2020-12-fable-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2020-12-fable/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2020-12-fable-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2020-12-fable-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2020-12-fable/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2020-12-fable-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-01-interpretations-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-01-interpretations/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-01-interpretations-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-01-interpretations-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-01-interpretations/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-01-interpretations-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-05-interview-contemporary-art-curator-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-05-interview-contemporary-art-curator/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-05-interview-contemporary-art-curator-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-05-interview-contemporary-art-curator-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-05-interview-contemporary-art-curator/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-05-interview-contemporary-art-curator-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-09-hide-and-seek-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-09-hide-and-seek/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-09-hide-and-seek-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-09-hide-and-seek-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-09-hide-and-seek/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-09-hide-and-seek-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-12-protiv-techeniya-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-12-protiv-techeniya/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-12-protiv-techeniya-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-12-protiv-techeniya-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2021-12-protiv-techeniya/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2021-12-protiv-techeniya-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2022-12-between-light-and-darkness-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2022-12-between-light-and-darkness/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2022-12-between-light-and-darkness-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2022-12-between-light-and-darkness-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/2022-12-between-light-and-darkness/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-2022-12-between-light-and-darkness-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-about-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/about/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-about-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-about-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/about/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-about-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-awards-publications-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/awards-publications/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-awards-publications-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-awards-publications-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/awards-publications/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-awards-publications-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-contact-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/contact/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-contact-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-contact-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/contact/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-contact-index-ru-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-exhibitions-index-en-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/exhibitions/index.en.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-exhibitions-index-en-mdx" */),
  "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-exhibitions-index-ru-mdx": () => import("./../../../src/templates/content-page.jsx?__contentFilePath=C:/uds/projects/yulame.site/src/content-pages/exhibitions/index.ru.mdx" /* webpackChunkName: "component---src-templates-content-page-jsx-content-file-path-c-uds-projects-yulame-site-src-content-pages-exhibitions-index-ru-mdx" */)
}

